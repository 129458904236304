
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {}
})
export default class Dashboard extends Vue {

    created() {}

}
