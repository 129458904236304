import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page_padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_localized_text, {
      class: "fw_600",
      type: "h3",
      localizedKey: "backoffice_Dashboard",
      text: "Dashboard"
    })
  ]))
}